import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5bfb7075"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "x-header-wrap"
};
const _hoisted_3 = {
  class: "x-header"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "translation"
};
const _hoisted_6 = {
  class: "right"
};
const _hoisted_7 = ["src"];
import { ref } from 'vue';
import { ElNotification } from 'element-plus';
import axios from 'axios';
import store from '../utils/store';
import LogoPosi from "@/components/LogoPosi.vue";
import router from "@/utils/router";
export default {
  __name: 'HomeFunc',
  setup(__props) {
    store.state.ifload = true;
    const languageMap = {
      en: {
        zhLan: 'Chinese',
        enLan: 'English',
        jpLan: 'Japan',
        choice: {
          source: 'Display Text',
          target: 'Display Translation'
        },
        back: 'Back',
        tips: {
          title: 'Tips',
          content: 'Please select the language you want to translate on the left'
        },
        tips2: {
          title: 'Loading',
          content: 'Loading,please wait!'
        },
        error: 'Server Error'
      },
      zh: {
        zhLan: '中文翻译',
        enLan: '英文翻译',
        jpLan: '日文翻译',
        choice: {
          source: '显示原文',
          target: '显示翻译'
        },
        back: '返回',
        tips: {
          title: '温馨提示',
          content: '请在左侧选择要翻译的语种'
        },
        tips2: {
          title: '加载中',
          content: '加载中，请稍后！'
        },
        error: '服务器出错'
      },
      jp: {
        zhLan: '中国語',
        enLan: '英語',
        jpLan: '日本語',
        choice: {
          source: 'テキストを表示',
          target: '翻訳を表示'
        },
        back: 'リターンマッチ',
        tips: {
          title: '暖かいヒント',
          content: '左側で翻訳する言語を選択してください'
        },
        tips2: {
          title: 'ロード中',
          content: 'ロード中、しばらくお待ちください'
        },
        error: 'サーバーでエラーが発生しました'
      }
    };
    const params = GetRequest();
    console.log("params>>>>>", params);
    const _path_ = params['path'];
    const sourceLan = store.state.sourceLanguage ? store.state.sourceLanguage : params['sourceLan'];
    const choiceLan = store.state.choiceLanguage ? store.state.choiceLanguage : params['choiceLan'];
    store.state.firstSpiderPage = store.state.firstSpiderPage ? store.state.firstSpiderPage : _path_;
    store.commit('setSourceLan', sourceLan);
    store.commit('changeLan', choiceLan);
    console.log("choiceLanguage", store.state.choiceLanguage);
    console.log("sourceLanguage", store.state.sourceLanguage);
    const currentPage = getPageLan();
    const languageCode = ref(store.state.choiceLanguage);
    const isTranslation = ref(store.state.choiceLanguage === store.state.sourceLanguage ? 2 : 1);
    const languageResult = ref(languageMap[languageCode.value]);
    function loadHostName() {
      console.log("没有获取吗");
      if (!store.state.spiderHostName) {
        try {
          const _iframe_ = document.getElementsByTagName('iframe')[0];
          const doc = _iframe_.contentDocument;
          console.log("获取iframe域名成功", doc);
          const _page_host_1_ = doc.querySelector('a[class="logo"], a[title="看漫画"], a[title="Manga Online"], a[class="myhost"]');
          console.log("获取iframe域名节点成功", _page_host_1_);
          const _url1_ = new URL(_page_host_1_.href);
          store.commit("setSpiderHostName", _url1_.origin);
        } catch (e) {
          console.log(e);
          console.log("获取iframe域名失败");
        }
      } else {
        console.log("已存在源网页域名");
      }
    }
    function getPageLan() {
      let _cp_ = ref('/index.html');
      if (sourceLan !== choiceLan) {
        console.log("走了吗");
        _cp_.value = `/trans(${choiceLan}).html`;
      }
      console.log("当前页面语种为：", _cp_.value);
      return _cp_;
    }

    /**
     *
     * @param idx
     * 1:显示翻译
     * 2:显示原文
     */
    function onChangeUrlData(idx) {
      store.state.ifload = true;
      if (idx === 1) {
        const language = store.state.choiceLanguage;
        const sourceLanguage = store.state.sourceLanguage;
        if (language && language !== sourceLanguage) {
          languageResult.value = languageMap[language];
          languageCode.value = language;
          currentPage.value = `/trans(${language}).html`;
          setTimeout(() => iframeLoad(), 3500);
        } else {
          ElNotification({
            title: languageResult.value.tips.title,
            message: languageResult.value.tips.content,
            type: 'warning'
          });
        }
      } else {
        // isTranslation.value = idx
        languageCode.value = store.state.sourceLanguage;
        languageResult.value = languageMap[store.state.sourceLanguage];
        currentPage.value = '/index.html';
      }
      store.state.ifload = false;
    }
    function onLanguage(language) {
      store.state.ifload = true;
      const sourceLan = store.state.sourceLanguage;
      if (language === sourceLan) {
        isTranslation.value = 2;
        languageCode.value = language;
        languageResult.value = languageMap[language];
        currentPage.value = '/index.html';
        store.state.ifload = false;
      } else {
        loadHostName();
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/trans/api/v1',
          data: {
            path: store.state.firstSpiderPage,
            language: language,
            host: store.state.spiderHostName
          }
        }).then(response => {
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            console.log("11111在这里点击了取消");
            return;
          }
          if (response['data'] == null) {
            ElNotification({
              title: 'error',
              message: languageResult.value['error'],
              type: 'error'
            });
          } else {
            if (response['data']['code'] === 200) {
              console.log("走了完整流程吗");
              languageCode.value = language;
              languageResult.value = languageMap[language];
              store.commit("changeLan", language);
              isTranslation.value = 1;
              currentPage.value = `/trans(${language}).html`;
              store.state.ifload = false;
            } else {
              ElNotification({
                message: response['data']['msg'],
                type: 'warning'
              });
              store.state.ifload = false;
            }
          }
        }).catch(() => {
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            console.log("2222222222在这里点击了取消");
            return;
          }
        });
      }
      // languageResult.value = languageMap[language]
      // store.commit("changeLan", language)
      setTimeout(() => iframeLoad(), 3500);
    }
    function getSpiderPage(url) {
      axios({
        method: 'GET',
        url: store.state.baseUrl + '/spider/page',
        params: {
          url: url,
          language: languageCode.value
        }
      }).then(response => {
        store.state.ifload = false;
        if (store.state.ifcancel) {
          store.state.ifcancel = false;
          return;
        }
        store.state.currentSpiderPage = response['data']['path'];
        store.commit('setSourceLan', response['data']['sourceLan']);
        store.commit('changeLan', response['data']['choiceLan']);
        router.push('/func/child');
      }).catch(() => {
        store.state.ifload = false;
        if (store.state.ifcancel) {
          store.state.ifcancel = false;
        }
      });
    }
    function getComicPage(url) {
      store.commit('funcChildData', url);
      store.state.ifload = true;
      axios({
        method: 'POST',
        url: store.state.baseUrl + '/spider/comic',
        data: {
          url: url,
          times: store.state.times,
          language: languageCode.value
        }
      }).then(response => {
        store.state.ifload = false;
        if (store.state.ifcancel) {
          store.state.ifcancel = false;
          return;
        }
        if (response['data'] == null) {
          ElNotification({
            title: 'error',
            message: languageResult.value['error'],
            type: 'error'
          });
        } else {
          store.state.comicName = response['data']['title'];
          store.state.comicChapter = response['data']['chapter'];
          store.state.index = response['data']['index'];
          store.commit('updataComic', response['data']['comic']);
          store.state.times = response['data']['times'];
          store.state.isTwo = response['data']['is_two'];
          router.push('/readcomic');
        }
      }).catch(() => {
        store.state.ifload = false;
        if (store.state.ifcancel) {
          store.state.ifcancel = false;
          return;
        }
      });
    }
    function GetRequest() {
      //url例子：www.baidu.com?id="123456"&name="www"；
      const url = decodeURI(window.location.search); //?id="123456"&name="www";
      let object = {};
      if (url.indexOf("?") !== -1)
        //url中存在问号，也就说有参数。
        {
          const str = url.substr(1); //得到?后面的字符串
          const strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
          for (let i = 0; i < strs.length; i++) {
            object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
          }
        }
      return object;
    }
    function handleIFrameLoad() {
      console.log("iframe加载完毕");
      // 延迟 2 秒后开始监听点击事件
      delayExecution(setupClickListeners, 2000);
    }
    function setupClickListeners() {
      const _iframe_ = document.querySelector('#pageIframe');
      const iframeDoc = _iframe_.contentWindow.document;
      const links = iframeDoc.getElementsByTagName('a');
      Array.from(links).forEach(link => {
        link.addEventListener('click', function (event) {
          event.preventDefault(); // 阻止默认行为
          console.log('Clicked link:', this.href, event);
          // 你可以在这里做进一步的操作
          ElNotification({
            title: languageResult.value.tips2.title,
            message: languageResult.value.tips2.content,
            type: 'info'
          });
          store.state.ifload = true;
          let url = new URL(this.href);
          console.log("点击了链接", url);
          const ignoreOrigin = ['http://127.0.0.1:10101', 'https://t9.swdz.com'];
          if (ignoreOrigin.indexOf(url.origin) !== -1) {
            if (!store.state.spiderHostName) {
              // 这个网站只有相对路径，要获取到host并设置
              const _page_host_ = iframeDoc.querySelector('a[title="看漫画"]').href;
              const _url_ = new URL(_page_host_);
              store.commit("setSpiderHostName", _url_.origin);
            }
            url = store.state.spiderHostName + url.pathname + url.search + url.hash;
          } else {
            url = url.href;
          }
          console.log("格式化之后的链接", url);
          const go_comic = gou_to_comic(url);
          if (go_comic) {
            const _u_ = new URL(url);
            getChapterUrl(_u_, iframeDoc);
            getComicPage(url);
            return;
          }
          store.commit('funcChildData', url);
          store.state.currentSpiderUrl = url;
          getSpiderPage(url);
        });
      });
    }

    // 判断iframe是否加载完成
    function iframeLoad() {
      console.log("开始执行iframe是否完成的函数");
      const iframe = document.querySelector('#pageIframe');
      console.log("iframe标签", iframe);
      iframe.onload = function () {
        // iframe加载完毕以后执行操作
        loadHostName();
        console.log('iframe已加载完毕');
        store.state.ifload = false;
        console.log("load=false");
      };
    }
    setTimeout(() => iframeLoad(), 1500);
    function delayExecution(callback, delay) {
      store.state.ifload = true;
      setTimeout(callback, delay);
    }
    function gou_to_comic(url) {
      let go_comic = false;
      const _u_ = new URL(url);
      let _url_ = url.replace('https://', '').replace('http://', '');
      if (_url_[_url_.length - 1] === '/') {
        _url_ = _url_.slice(0, _url_.length - 1);
      }
      const url_lst = _url_.split('/');
      if (_u_.origin === 'https://www.manhuagui.com') {
        if (url_lst.length === 4) {
          go_comic = true;
        }
      } else if (_u_.origin === 'https://manga1000.cc') {
        if (url_lst.length === 4) {
          go_comic = true;
        }
      } else if (_u_.origin === 'https://chapmanganato.to' || _u_.origin === 'https://manganato.com') {
        if (url_lst.length === 3) {
          go_comic = true;
        }
      }
      return go_comic;
    }
    function getChapterUrl(url, iframeDoc) {
      let all_links = [];
      if (url.origin === 'https://manga1000.cc') {
        const chapterDiv = iframeDoc.querySelectorAll('.chapter.col-xs-5');
        console.log(chapterDiv);
        for (const per of chapterDiv) {
          console.log(per);
          const a_link = per.querySelector('a');
          all_links.push(a_link.href);
        }
      }
      const idx = all_links.indexOf(url.href);
      console.log(url);
      console.log(all_links);
      store.commit("setChapterUrls", all_links);
      store.commit("setCurrentChapterIdx", idx);
    }
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(LogoPosi, {
        style: {
          "height": "100px"
        }
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'zh' ? 'span-active' : ''),
        onClick: _cache[0] || (_cache[0] = $event => onLanguage('zh'))
      }, _toDisplayString(languageResult.value.zhLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'en' ? 'span-active' : ''),
        onClick: _cache[1] || (_cache[1] = $event => onLanguage('en'))
      }, _toDisplayString(languageResult.value.enLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'jp' ? 'span-active' : ''),
        onClick: _cache[2] || (_cache[2] = $event => onLanguage('jp'))
      }, _toDisplayString(languageResult.value.jpLan), 3)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_select, {
        modelValue: isTranslation.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => isTranslation.value = $event),
        onChange: onChangeUrlData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          label: languageResult.value.choice.target,
          value: 1
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.target), 1)]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_option, {
          label: languageResult.value.choice.source,
          value: 2
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.source), 1)]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"])])])]), _withDirectives(_createElementVNode("iframe", {
        id: "pageIframe",
        onLoad: handleIFrameLoad,
        src: `${_unref(store).state.baseUrl + _unref(store).state.firstSpiderPage}${_unref(currentPage)}`
      }, null, 40, _hoisted_7), [[_directive_loading, _ctx.ifload]])]);
    };
  }
};