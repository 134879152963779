import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fbf599fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "100%"
  }
};
import { ref, computed } from 'vue';
import store from '@/utils/store';
export default {
  __name: 'FuncPage',
  setup(__props) {
    // import LogoPosi from '@/components/LogoPosi.vue';
    const languageResult = computed(() => {
      const language = ref(store.state.choiceLanguage);
      const language_map = {
        zh: '取消请求',
        en: 'CANCEL',
        jp: 'キャンセル'
      };
      return language_map[language.value];
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_router_view = _resolveComponent("router-view");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_el_button, {
        type: "primary",
        style: {
          "position": "absolute",
          "top": "5rem",
          "left": "5rem",
          "z-index": "10001"
        },
        onClick: _cache[0] || (_cache[0] = $event => {
          _unref(store).state.ifcancel = true;
          _unref(store).state.ifload = false;
        })
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value), 1)]),
        _: 1
      }, 512), [[_vShow, _unref(store).state.ifload]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)])), [[_directive_loading, _unref(store).state.ifload]])], 64);
    };
  }
};