import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bbe74c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "x-header-wrap"
};
const _hoisted_3 = {
  class: "x-header"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "translation"
};
const _hoisted_6 = {
  class: "right"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  "data-v-adb259f5": "",
  class: "advert-right"
};
const _hoisted_9 = ["href"];
const _hoisted_10 = {
  "data-v-adb259f5": "",
  class: "el-image"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  "data-v-adb259f4": "",
  class: "advert-left"
};
const _hoisted_13 = ["href"];
const _hoisted_14 = {
  "data-v-adb259f4": "",
  class: "el-image"
};
const _hoisted_15 = ["src"];
import { ref } from 'vue';
import { ElNotification } from 'element-plus';
import store from '../utils/store';
import axios from 'axios';
import router from '@/utils/router';
import LogoPosi from "@/components/LogoPosi.vue";
export default {
  __name: 'ChildFunc',
  setup(__props) {
    store.state.ifload = true;
    const ads = ref(store.state.adsLst);
    const pageAds = {
      left_pos: getPositionAds(8),
      right_pos: getPositionAds(9)
    };
    const languageMap = {
      en: {
        zhLan: 'Chinese',
        enLan: 'English',
        jpLan: 'Japan',
        choice: {
          source: 'Display Text',
          target: 'Display Translation'
        },
        back: 'Back',
        tips: {
          title: 'Tips',
          content: 'Please select the language you want to translate on the left'
        },
        tips2: {
          title: 'Loading',
          content: 'Loading,please wait!'
        },
        error: 'Server Error'
      },
      zh: {
        zhLan: '中文翻译',
        enLan: '英文翻译',
        jpLan: '日文翻译',
        choice: {
          source: '显示原文',
          target: '显示翻译'
        },
        back: '返回',
        tips: {
          title: '温馨提示',
          content: '请在左侧选择要翻译的语种'
        },
        tips2: {
          title: '加载中',
          content: '加载中，请稍后！'
        },
        error: '服务器出错'
      },
      jp: {
        zhLan: '中国語',
        enLan: '英語',
        jpLan: '日本語',
        choice: {
          source: 'テキストを表示',
          target: '翻訳を表示'
        },
        back: 'リターンマッチ',
        tips: {
          title: '暖かいヒント',
          content: '左側で翻訳する言語を選択してください'
        },
        tips2: {
          title: 'ロード中',
          content: 'ロード中、しばらくお待ちください'
        },
        error: 'サーバーでエラーが発生しました'
      }
    };
    const languageCode = ref(store.state.choiceLanguage);
    const isTranslation = ref(store.state.choiceLanguage === store.state.sourceLanguage ? 2 : 1);
    const languageResult = ref(languageMap[languageCode.value]);
    const pageName = getPageLan();
    const urlData = ref(store.state.baseUrl + store.state.currentSpiderPage + pageName.value);
    const uToken = ref(store.state.token);
    const childUrl = ref(store.state.funcChild);
    function pushUserRecords() {
      if (uToken.value) {
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/api/user/records',
          data: {
            url: childUrl.value,
            path: store.state.currentSpiderPage,
            language: store.state.choiceLanguage,
            sourceLanguage: store.state.sourceLanguage,
            token: uToken.value
          }
        }).then(response => {
          if (response['data'] == null) {
            console.log("观看记录上传失败");
          } else {
            console.log("观看记录上传成功");
          }
        }).catch(() => {
          console.log("观看记录上传失败时网络异常");
        });
      }
    }
    pushUserRecords();
    function getPositionAds(id) {
      let adv = {
        'link': '#',
        'image': ''
      };
      Array.from(ads.value).forEach(link => {
        if (link['id'] === id) {
          adv = link;
        }
      });
      return adv;
    }
    function loadHostName() {
      if (!store.state.spiderHostName) {
        try {
          const _iframe_ = document.getElementsByTagName('iframe')[0];
          const doc = _iframe_.contentDocument;
          const _page_host_1_ = doc.querySelector('a[class="logo"], a[title="看漫画"], a[title="Manga Online"], a[class="myhost"]');
          const _url1_ = new URL(_page_host_1_.href);
          store.commit("setSpiderHostName", _url1_.origin);
        } catch (e) {
          console.log(e);
          console.log("获取iframe域名失败");
        }
      } else {
        console.log("已存在源网页域名");
      }
    }
    function getPageLan() {
      let _cp_ = ref('/index.html');
      if (isTranslation.value === 1) {
        _cp_.value = `/trans(${languageCode.value}).html`;
      }
      console.log("当前页面语种为：", _cp_.value);
      return _cp_;
    }

    /**
     *
     * @param idx
     * 1:显示翻译
     * 2:显示原文
     */
    function onChangeUrlData(idx) {
      store.state.ifload = true;
      if (idx === 1) {
        const language = store.state.choiceLanguage;
        const sourceLanguage = store.state.sourceLanguage;
        if (language && language !== sourceLanguage) {
          languageResult.value = languageMap[language];
          languageCode.value = language;
          urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/trans(${language}).html`;
          setTimeout(() => iframeLoad(), 3500);
        } else {
          ElNotification({
            title: languageResult.value.tips.title,
            message: languageResult.value.tips.content,
            type: 'warning'
          });
        }
      } else {
        // isTranslation.value = idx
        languageCode.value = store.state.sourceLanguage;
        languageResult.value = languageMap[store.state.sourceLanguage];
        urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/index.html`;
        // currentPage.value = '/index.html'
      }
      store.state.ifload = false;
    }
    function onLanguage(language) {
      store.state.ifload = true;
      // languageCode.value = language
      const sourceLan = store.state.sourceLanguage;
      if (language === sourceLan) {
        isTranslation.value = 2;
        languageCode.value = language;
        languageResult.value = languageMap[language];
        urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/index.html`;
        store.state.ifload = false;
        // currentPage.value = '/index.html'
      } else {
        loadHostName();
        axios({
          method: 'POST',
          url: store.state.baseUrl + '/trans/api/v1',
          data: {
            path: store.state.currentSpiderPage,
            language: language,
            host: store.state.spiderHostName
          }
        }).then(response => {
          console.log('onLanguage函数ifload结束');
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
          if (response['data'] == null) {
            ElNotification({
              title: 'error',
              message: languageResult.value['error'],
              type: 'error'
            });
          } else {
            if (response['data']['code'] === 200) {
              languageCode.value = language;
              languageResult.value = languageMap[language];
              store.commit("changeLan", language);
              isTranslation.value = 1;
              urlData.value = store.state.baseUrl + store.state.currentSpiderPage + `/trans(${language}).html`;
              store.state.ifload = false;
            } else {
              ElNotification({
                message: response['data']['msg'],
                type: 'warning'
              });
              store.state.ifload = false;
            }
          }
        }).catch(() => {
          if (store.state.ifcancel) {
            store.state.ifcancel = false;
            return;
          }
        });
      }
      // languageResult.value = languageMap[language]
      // store.commit("changeLan", language)
      setTimeout(() => iframeLoad(), 3500);
    }
    function handleIFrameLoad() {
      // 延迟 2 秒后开始监听点击事件
      delayExecution(setupClickListeners, 3500);
    }
    function setupClickListeners() {
      const _iframe_ = document.querySelector('#pageIframe');
      const iframeDoc = _iframe_.contentWindow.document;
      const links = iframeDoc.getElementsByTagName('a');
      console.log("links>>>>>>>>>>>", links);
      Array.from(links).forEach(link => {
        link.addEventListener('click', function (event) {
          event.preventDefault(); // 阻止默认行为
          console.log('Clicked link:', this.href, event);
          // 你可以在这里做进一步的操作
          ElNotification({
            title: languageResult.value.tips2.title,
            message: languageResult.value.tips2.content,
            type: 'info'
          });
          let url = new URL(this.href);
          console.log(url);
          const ignoreOrigin = ['http://127.0.0.1:10101', 'https://t9.swdz.com'];
          if (ignoreOrigin.indexOf(url.origin) !== -1) {
            url = store.state.spiderHostName + url.pathname + url.search + url.hash;
            url = new URL(url);
          }
          getChapterUrl(url, iframeDoc);
          store.commit('funcChildData', url);
          store.state.ifload = true;
          console.log('setupClickListeners函数ifload开始');
          axios({
            method: 'POST',
            url: store.state.baseUrl + '/spider/comic',
            data: {
              url: url,
              times: store.state.times,
              language: languageCode.value
            }
          }).then(response => {
            store.state.ifload = false;
            console.log('setupClickListeners函数ifload结束');
            if (store.state.ifcancel) {
              store.state.ifcancel = false;
              return;
            }
            if (response['data'] == null) {
              ElNotification({
                title: 'error',
                message: languageResult.value['error'],
                type: 'error'
              });
            } else {
              store.state.comicName = response['data']['title'];
              store.state.comicChapter = response['data']['chapter'];
              store.state.index = response['data']['index'];
              store.commit('updataComic', response['data']['comic']);
              store.state.times = response['data']['times'];
              store.state.isTwo = response['data']['is_two'];
              router.push('/readcomic');
            }
          }).catch(() => {
            store.state.ifload = false;
            console.log('setupClickListeners函数ifload结束');
            if (store.state.ifcancel) {
              store.state.ifcancel = false;
              return;
            }
          });
        });
      });
    }
    function delayExecution(callback, delay) {
      store.state.ifload = true;
      console.log('delayExecution函数ifload开始');
      setTimeout(callback, delay);
      store.state.ifload = false;
      console.log('delayExecution函数ifload结束');
    }

    // 判断iframe是否加载完成
    function iframeLoad() {
      console.log("开始执行iframe是否完成的函数");
      const iframe = document.querySelector('#pageIframe');
      console.log("iframe标签", iframe);
      iframe.onload = function () {
        // iframe加载完毕以后执行操作
        console.log('iframe已加载完毕');
        loadHostName();
        store.state.ifload = false;
      };
    }
    setTimeout(() => iframeLoad(), 3500);
    function getChapterUrl(url, iframeDoc) {
      const manganto = ["https://manganato.com", "https://chapmanganato.to"];
      let all_links = [];
      if (url.origin === 'https://manga1000.cc') {
        const chapterDiv = iframeDoc.querySelectorAll('.chapter.col-xs-5');
        for (const per of chapterDiv) {
          const a_link = per.querySelector('a');
          all_links.push(a_link.href);
        }
      } else if (url.origin === 'https://www.manhuagui.com') {
        let chapterDiv = iframeDoc.querySelectorAll('.chapter-list')[0].querySelectorAll('ul');
        chapterDiv = Array.from(chapterDiv);
        chapterDiv.reverse();
        for (const per of chapterDiv) {
          const li_link = per.querySelectorAll('li');
          for (const li of li_link) {
            let a_link = li.querySelector('a').href;
            try {
              const a_href = new URL(a_link);
              const ignoreOrigin = ['http://127.0.0.1:10101', 'https://t9.swdz.com'];
              if (ignoreOrigin.indexOf(a_href.origin) !== -1) {
                a_link = store.state.spiderHostName + a_href.pathname + a_href.search + a_href.hash;
              }
            } catch (e) {
              console.log(e);
            }
            all_links.push(a_link);
          }
        }
      } else if (manganto.indexOf(url.origin) !== -1) {
        const chapterDiv = iframeDoc.querySelectorAll("li[class='a-h']");
        for (const per of chapterDiv) {
          const a_link = per.querySelector('a');
          all_links.push(a_link.href);
        }
      }
      const idx = all_links.indexOf(url.href);
      store.commit("setChapterUrls", all_links);
      store.commit("setCurrentChapterIdx", idx);
    }
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(LogoPosi, {
        style: {
          "height": "100px"
        }
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'zh' ? 'span-active' : ''),
        onClick: _cache[0] || (_cache[0] = $event => onLanguage('zh'))
      }, _toDisplayString(languageResult.value.zhLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'en' ? 'span-active' : ''),
        onClick: _cache[1] || (_cache[1] = $event => onLanguage('en'))
      }, _toDisplayString(languageResult.value.enLan), 3), _createElementVNode("span", {
        class: _normalizeClass(languageCode.value === 'jp' ? 'span-active' : ''),
        onClick: _cache[2] || (_cache[2] = $event => onLanguage('jp'))
      }, _toDisplayString(languageResult.value.jpLan), 3)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_select, {
        modelValue: isTranslation.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => isTranslation.value = $event),
        onChange: onChangeUrlData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          label: languageResult.value.choice.target,
          value: 1
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.target), 1)]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_option, {
          label: languageResult.value.choice.source,
          value: 2
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.choice.source), 1)]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        style: {
          "margin-left": "10px"
        },
        type: "primary",
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).back())
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(languageResult.value.back), 1)]),
        _: 1
      })])])]), _withDirectives(_createElementVNode("iframe", {
        id: "pageIframe",
        onLoad: handleIFrameLoad,
        src: urlData.value
      }, null, 40, _hoisted_7), [[_directive_loading, _ctx.ifload]]), _createElementVNode("div", _hoisted_8, [_createElementVNode("a", {
        "data-v-adb259f5": "",
        href: pageAds.right_pos.link,
        target: "_blank",
        underline: "false"
      }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
        src: pageAds.right_pos.image,
        class: "el-image__inner",
        style: {
          "object-fit": "fill",
          "min-width": "5rem"
        }
      }, null, 8, _hoisted_11)])], 8, _hoisted_9)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("a", {
        "data-v-adb259f4": "",
        href: pageAds.left_pos.link,
        target: "_blank",
        underline: "false"
      }, [_createElementVNode("div", _hoisted_14, [_createElementVNode("img", {
        src: pageAds.left_pos.image,
        class: "el-image__inner",
        style: {
          "object-fit": "fill",
          "min-width": "5rem"
        }
      }, null, 8, _hoisted_15)])], 8, _hoisted_13)])]);
    };
  }
};